export const environment = {
  production: false,
  title: 'uat',
  NODE_ENV:'uat',
  url: 'https://groupauthenticator-uat.test.bcbsil.com/',

  AM_URL: "https://bim.hcsctest.net/am",
  AM_URL_IL: "https://bim.test.bcbsil.com/am",
  AM_URL_TX: "https://bim.test.bcbstx.com/am",
  AM_URL_MT: "https://bim.test.bcbsmt.com/am",
  AM_URL_OK: "https://bim.test.bcbsok.com/am",
  AM_URL_NM: "https://bim.test.bcbsnm.com/am",
  REALM_PATH: 'B2BApps',
  DEFAULT_TREE: 'HCSC-B2B-User-Login',
  FORGOT_PASSWORD_TREE: 'HCSC-Forgot-Password',

  BAE_TOU_IL: 'https://groupauthenticator-uat.test.bcbsil.com/content/dam/hcsc/bae/tou.generic.json/bae/states/il1',
  BAE_TOU_TX: 'https://groupauthenticator-uat.test.bcbstx.com/content/dam/hcsc/bae/tou.generic.json/bae/states/tx1',
  BAE_TOU_MT: 'https://groupauthenticator-uat.test.bcbsmt.com/content/dam/hcsc/bae/tou.generic.json/bae/states/mt1',
  BAE_TOU_OK: 'https://groupauthenticator-uat.test.bcbsok.com/content/dam/hcsc/bae/tou.generic.json/bae/states/ok1',
  BAE_TOU_NM: 'https://groupauthenticator-uat.test.bcbsnm.com/content/dam/hcsc/bae/tou.generic.json/bae/states/nm1',

  BAP_TOU_IL: 'https://groupauthenticator-uat.test.bcbsil.com/content/dam/hcsc/bap/tou.generic.json/bap/states/illinois',
  BAP_TOU_TX: 'https://groupauthenticator-uat.test.bcbstx.com/content/dam/hcsc/bap/tou.generic.json/bap/states/texas',
  BAP_TOU_MT: 'https://groupauthenticator-uat.test.bcbsmt.com/content/dam/hcsc/bap/tou.generic.json/bap/states/montana',
  BAP_TOU_OK: 'https://groupauthenticator-uat.test.bcbsok.com/content/dam/hcsc/bap/tou.generic.json/bap/states/oklahoma',
  BAP_TOU_NM: 'https://groupauthenticator-uat.test.bcbsnm.com/content/dam/hcsc/bap/tou.generic.json/bap/states/new-mexico',

  EmployerValidatonUri: 'https://bim.hcsctest.net/ServiceLayer/public/v1/employer/validation',
  ProducerValidationUri: 'https://bim.hcsctest.net/ServiceLayer/public/v1/producer/validation',

  BAE_FORWARD_IL:'https://employersportalprodfix.test.bcbsil.com/wps/BAEInitPortlets/frlogin',
  BAE_FORWARD_TX:'https://employersportalprodfix.test.bcbstx.com/wps/BAEInitPortlets/frlogin',
  BAE_FORWARD_MT:'https://employersportalprodfix.test.bcbsmt.com/wps/BAEInitPortlets/frlogin',
  BAE_FORWARD_OK:'https://employersportalprodfix.test.bcbsok.com/wps/BAEInitPortlets/frlogin',
  BAE_FORWARD_NM:'https://employersportalprodfix.test.bcbsnm.com/wps/BAEInitPortlets/frlogin',
  
  BAE_LEGAL_IL:'https://www.bcbsil.com/employer/legal-and-privacy-bae/',
  BAE_LEGAL_TX:'https://www.bcbstx.com/employer/legal-and-privacy-bae/',
  BAE_LEGAL_MT:'https://www.bcbsmt.com/employer/legal-and-privacy-bae/',
  BAE_LEGAL_OK:'https://www.bcbsok.com/employer/legal-and-privacy-bae/',
  BAE_LEGAL_NM:'https://www.bcbsnm.com/employer/legal-and-privacy-bae/',
  
  BAP_LEGAL_IL:'https://www.bcbsil.com/producer/legal-and-privacy-bap',
  BAP_LEGAL_TX:'https://www.bcbstx.com/producer/legal-and-privacy-bap',
  BAP_LEGAL_MT:'https://www.bcbsmt.com/producer/legal-and-privacy-bap',
  BAP_LEGAL_OK:'https://www.bcbsok.com/producer/legal-and-privacy-bap',
  BAP_LEGAL_NM:'https://www.bcbsnm.com/producer/legal-and-privacy-bap',
  

  BAP_FORWARD_IL:'https://blueaccessforproducers-uat.test.bcbsil.com/producers/login',
  BAP_FORWARD_TX:'https://blueaccessforproducers-uat.test.bcbstx.com/producers/login',
  BAP_FORWARD_MT:'https://blueaccessforproducers-uat.test.bcbsmt.com/producers/login',
  BAP_FORWARD_OK:'https://blueaccessforproducers-uat.test.bcbsok.com/producers/login',
  BAP_FORWARD_NM:'https://blueaccessforproducers-uat.test.bcbsnm.com/producers/login',
  
  BAP_DOMAIN_IL:'.bcbsil.com',
  BAP_DOMAIN_TX:'.bcbstx.com',
  BAP_DOMAIN_MT:'.bcbsmt.com',
  BAP_DOMAIN_OK:'.bcbsok.com',
  BAP_DOMAIN_NM:'.bcbsnm.com',
  
  BAE_APP: "[{\"hcscAppID\":\"APP00000430\"}]",
  BAP_APP: "[{\"hcscAppID\":\"APP00000061\"}]",

  FORGOT_USERID_IL: 'https://bim.test.bcbsil.com/openidm/endpoint/forgotUsername?_action=forgotUsername',
  FORGOT_USERID_TX: 'https://bim.test.bcbstx.com/openidm/endpoint/forgotUsername?_action=forgotUsername',
  FORGOT_USERID_MT: 'https://bim.test.bcbsmt.com/openidm/endpoint/forgotUsername?_action=forgotUsername',
  FORGOT_USERID_OK: 'https://bim.test.bcbsok.com/openidm/endpoint/forgotUsername?_action=forgotUsername',
  FORGOT_USERID_NM: 'https://bim.test.bcbsnm.com/openidm/endpoint/forgotUsername?_action=forgotUsername',

  REGISTER_URL_BAE_IL:'https://bim.test.bcbsil.com/am/json/realms/root/realms/B2BApps/authenticate?authIndexType=service&authIndexValue=HCSC-Self-Reg&noSession=true',
  REGISTER_URL_BAE_TX:'https://bim.test.bcbstx.com/am/json/realms/root/realms/B2BApps/authenticate?authIndexType=service&authIndexValue=HCSC-Self-Reg&noSession=true',
  REGISTER_URL_BAE_MT:'https://bim.test.bcbsmt.com/am/json/realms/root/realms/B2BApps/authenticate?authIndexType=service&authIndexValue=HCSC-Self-Reg&noSession=true',
  REGISTER_URL_BAE_OK:'https://bim.test.bcbsok.com/am/json/realms/root/realms/B2BApps/authenticate?authIndexType=service&authIndexValue=HCSC-Self-Reg&noSession=true',
  REGISTER_URL_BAE_NM:'https://bim.test.bcbsnm.com/am/json/realms/root/realms/B2BApps/authenticate?authIndexType=service&authIndexValue=HCSC-Self-Reg&noSession=true',

  REGISTER_URL_BAP_IL:'https://bim.test.bcbsil.com/am/json/realms/root/realms/B2BApps/authenticate?authIndexType=service&authIndexValue=HCSC-BAP-Registration&noSession=true',
  REGISTER_URL_BAP_TX:'https://bim.test.bcbstx.com/am/json/realms/root/realms/B2BApps/authenticate?authIndexType=service&authIndexValue=HCSC-BAP-Registration&noSession=true',
  REGISTER_URL_BAP_MT:'https://bim.test.bcbsmt.com/am/json/realms/root/realms/B2BApps/authenticate?authIndexType=service&authIndexValue=HCSC-BAP-Registration&noSession=true',
  REGISTER_URL_BAP_OK:'https://bim.test.bcbsok.com/am/json/realms/root/realms/B2BApps/authenticate?authIndexType=service&authIndexValue=HCSC-BAP-Registration&noSession=true',
  REGISTER_URL_BAP_NM:'https://bim.test.bcbsnm.com/am/json/realms/root/realms/B2BApps/authenticate?authIndexType=service&authIndexValue=HCSC-BAP-Registration&noSession=true',

  BAE_APP_ID:'APP00000430',
  BAP_APP_ID:'APP00000061',
  
  BAE_RESEND_EMAIL: 'https://bim.hcsctest.net/openidm/endpoint/hcscSendVerificationEmail?_action=sendVerification',

  BAE_CONTACT_US_IL: 'https://www.bcbsil.com/employer/contact-us/contacts/contacts-information',
  BAE_CONTACT_US_TX: 'https://www.bcbstx.com/employer/contact-us/contacts/contact',
  BAE_CONTACT_US_NM: 'https://www.bcbsnm.com/employer/contact-us/contacts/contact-information',
  BAE_CONTACT_US_OK: 'https://www.bcbsok.com/employer/contact-us/contacts/contact',
  BAE_CONTACT_US_MT: 'https://www.bcbsmt.com/employer/contact-us/contacts/contact-information',
  
  BAP_FAQ_IL: 'https://www.bcbsilcommunications.com/newsletters/files/2024/producer/bap-two-step-login-and-faqs.pdf',
  BAP_FAQ_TX: 'https://www.bcbsilcommunications.com/newsletters/files/2024/producer/bap-two-step-login-and-faqs.pdf',
  BAP_FAQ_NM: 'https://www.bcbsilcommunications.com/newsletters/files/2024/producer/bap-two-step-login-and-faqs.pdf',
  BAP_FAQ_OK: 'https://www.bcbsilcommunications.com/newsletters/files/2024/producer/bap-two-step-login-and-faqs.pdf',
  BAP_FAQ_MT: 'https://www.bcbsilcommunications.com/newsletters/files/2024/producer/bap-two-step-login-and-faqs.pdf',
  
  BAE_SHOW_LINK: true,
  BAP_SHOW_LINK: true,

  BAE_USER_ROLE: '[\"EMP_SCP_DOCUMENTATION\",\"Emp Delegated Admin\",\"Emp HSA Account Inquiry\",\"Emp Maintain Payment Profile\",\"Emp Maintenance User\",\"Emp Release Payment\",\"Emp Report User\",\"Emp Request Payment\",\"Emp View ASO Bill\",\"Emp View ASO Claims\",\"Emp View ASO Subscriber List\",\"Emp View Content\",\"Emp View Membership\",\"Emp View Premium Bill\",\"Emp ASO Pay Bill\",\"Emp Group Secured Messaging\"]',

  BAP_USER_ROLE:{
   	IL:'[\"AMA QDA\",\"EMP_BROKER_INQUIRY_USER\",\"AMA Commission Statements\",\"EMP_BROKER_MAINTENANCE_USER\",\"BAP Blue Insight\",\"AMA BlueTrack\",\"BAP O65 INQUIRY\",\"SGE BROKER\"]',
    NM:'[\"AMA Broker NM\",\"EMP_BROKER_INQUIRY_USER\",\"EMP_BROKER_MAINTENANCE_USER\", \"AMA Commission Statements\",\"BAP O65 INQUIRY\",\"BAP Blue Insight\",\"SGE BROKER\"]',
    OK:'[\"AMA Broker OK\",\"EMP_BROKER_INQUIRY_USER\",\"AMA OK Commission Statements\",\"EMP_BROKER_MAINTENANCE_USER\",\"BAP O65 INQUIRY\",\"BAP Blue Insight\",\"SGE BROKER\"]',
    TX:'[\"AMA Broker\",\"EMP_BROKER_INQUIRY_USER\",\"AMA Commission Statements\",\"EMP_BROKER_MAINTENANCE_USER\",\"BAP O65 INQUIRY\",\"BAP Blue Insight\",\"SGE BROKER\",\"RC BROKER\"]',
    MT:'[\"AMA Broker MT\",\"EMP_BROKER_INQUIRY_USER\",\"EMP_BROKER_MAINTENANCE_USER\",\"AMA OK Commission Statements\",\"BAP O65 INQUIRY\",\"BAP Blue Insight\",\"SGE BROKER\"]'}


};
